<template>
  <div class="card is-shadowless">
    <div class="card-content">
      <header class="mb-5">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <h2 class="is-size-6 has-text-dark has-text-weight-bold mr-3">Nota Pembelian</h2>
          <div @click="$emit('close')">
            <b-icon class="cursor-pointer" icon="close-thick"/>
          </div>
        </div>
      </header>
      <section class="my-2">
        <ValidationObserver ref="form">
          <form autocomplete="off" @submit.prevent="handleSubmit(false)">
            <ValidationProvider name="Nama Petambak" rules="required" v-slot="{ errors }">
              <b-field label="Nama Petambak" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input v-model="data.farmer_name" disabled/>
              </b-field>
            </ValidationProvider>
            <ValidationProvider name="Lokasi Petambak" rules="required" v-slot="{ errors }">
              <b-field label="Lokasi Petambak" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input v-model="data.farmer_location" disabled/>
              </b-field>
            </ValidationProvider>
            <ValidationProvider name="Nama Staf" rules="required" v-slot="{ errors }">
              <b-field label="Nama Staf" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input v-model="data.staff_name" disabled/>
              </b-field>
            </ValidationProvider>
            <ValidationProvider name="Tanggal Panen" rules="required" v-slot="{ errors }">
              <b-field label="Tanggal Panen" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-datepicker placeholder="Pilih Tanggal"
                              open-on-focus
                              v-model="data.harvest_date"
                              disabled
                              locale="id-ID">
                </b-datepicker>
              </b-field>
            </ValidationProvider>
            <ValidationProvider name="Tipe Panen" rules="required" v-slot="{ errors }">
              <b-field label="Tipe Panen" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-select placeholder="Pilih Tipe" v-model="data.type" expanded disabled>
                  <option value="Parsial">Parsial</option>
                  <option value="Total">Total</option>
                </b-select>
              </b-field>
            </ValidationProvider>

            <div class="is-divider"></div>

            <h2 class="has-text-weight-bold ml-3 is-size-4">Petak</h2>

            <div class="is-divider"></div>

            <b-table :data='data.crops' :mobile-cards="false">

              <b-table-column label="Nama Petak" field="name" v-slot="props">
                <ValidationProvider :name="`Nama Petak ${props.index + 1}`" v-slot="{ errors }">
                  <b-field class="mb-0" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input v-model="props.row.name" disabled/>
                  </b-field>
                </ValidationProvider>
              </b-table-column>

              <b-table-column label="Size" field="size" v-slot="props">
                <ValidationProvider :name="`Size ${props.index + 1}`" v-slot="{ errors }">
                  <b-field class="mb-0" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input type="number" step="any" v-model.number="props.row.size" disabled/>
                  </b-field>
                </ValidationProvider>
              </b-table-column>

              <b-table-column label="Harga/Kg" field="price" v-slot="props">
                <ValidationProvider :name="`Harga/Kg ${props.index + 1}`" v-slot="{ errors }">
                  <b-field class="mb-0" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input type="text" v-model="props.row.price_per_kg_text"
                             v-currency="currencyOptions"
                             @input="value => updateTotalPrice(props.row, value)"/>
                  </b-field>
                </ValidationProvider>
              </b-table-column>

              <b-table-column label="Quantity (Kg)" field="qty" v-slot="props">
                <ValidationProvider :name="`Quantity ${props.index + 1}`" v-slot="{ errors }">
                  <b-field class="mb-0" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input type="number" step="any" v-model.number="props.row.qty" @input="updateTotalPrice(props.row)" disabled/>
                  </b-field>
                </ValidationProvider>
              </b-table-column>

              <b-table-column label="Total" field="price" v-slot="props">
                <p style="min-width: 150px">{{ $currency(props.row.total_price) }}</p>
              </b-table-column>
<!--              <b-table-column label="Undersize" field="undersize" v-slot="props">-->
<!--                <ValidationProvider :name="`Undersize ${props.index + 1}`" v-slot="{ errors }">-->
<!--                  <b-field class="mb-0" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">-->
<!--                    <b-input type="number" step="any" v-model.number="props.row.undersize"/>-->
<!--                  </b-field>-->
<!--                </ValidationProvider>-->
<!--              </b-table-column>-->
<!--              <b-table-column label="Molting" field="molting" v-slot="props">-->
<!--                <ValidationProvider :name="`Molting ${props.index + 1}`" v-slot="{ errors }">-->
<!--                  <b-field class="mb-0" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">-->
<!--                    <b-input type="number" step="any" v-model.number="props.row.molting"/>-->
<!--                  </b-field>-->
<!--                </ValidationProvider>-->
<!--              </b-table-column>-->
<!--              <b-table-column label="Kropos" field="hollow" v-slot="props">-->
<!--                <ValidationProvider :name="`Kropos ${props.index + 1}`" v-slot="{ errors }">-->
<!--                  <b-field class="mb-0" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">-->
<!--                    <b-input type="number" step="any" v-model.number="props.row.hollow"/>-->
<!--                  </b-field>-->
<!--                </ValidationProvider>-->
<!--              </b-table-column>-->

              <template #footer>
                <th colspan="4">Total</th>
                <th>{{ $currency(totalPrice) }}</th>
              </template>
            </b-table>

            <div class="is-divider"></div>

            <ValidationProvider name="Metode Pembayaran" rules="required" v-slot="{ errors }">
              <b-field label="Metode Pembayaran" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <div class="block">
                  <b-radio v-model="data.payment_method"
                           name="payment_method"
                           native-value="Cash">
                    Cash
                  </b-radio>
                  <b-radio v-model="data.payment_method"
                           name="payment_method"
                           native-value="Tempo Pembayaran">
                    Tempo Pembayaran
                  </b-radio>
                </div>
              </b-field>
            </ValidationProvider>

            <ValidationProvider name="Waktu Pembayaran"
                                :rules="{
                                  'required': data.payment_method === 'Tempo Pembayaran',
                                  'min': 1,
                                }" v-slot="{ errors }"
                                v-show="data.payment_method === 'Tempo Pembayaran'"
            >
              <b-field label="Metode Pembayaran" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input v-model.number="data.payment_time" step="1" type="number"/>
                <p class="control">
                  <b-button class="button is-primary">Minggu</b-button>
                </p>
              </b-field>
            </ValidationProvider>

            <b-field class="file is-primary" :class="{'has-name': !!files}">
              <div class="is-flex is-flex-direction-column is-align-items-start">
                <label class="label">Bukti Pembayaran</label>
                <b-upload v-model="files" multiple drag-drop expanded class="mb-2"
                          accept="image/jpeg,image/png,application/pdf">
                  <section class="section">
                    <div class="content has-text-centered">
                      <p>
                        <b-icon icon="upload" size="is-large"></b-icon>
                      </p>
                      <p>Drop your files here or click to upload</p>
                    </div>
                  </section>
                </b-upload>
                <div class="tags">
                <span v-for="(file, index) in files" :key="index" class="tag is-primary">
                  {{ file.name }}
                  <button class="delete is-small" type="button" @click="deleteDropFile(index)"></button>
                </span>
                </div>
                <div class="is-flex is-flex-wrap-wrap">
                  <template v-for="(file, index) in old_files">
                    <div class="is-flex is-relative" v-if="$isImage(file)" :key="index">
                      <img :src="file" class="cursor-pointer" alt=""
                           style="max-width: 300px" @click="openImage(file)">
                      <b-button type="is-danger" icon-left="close" class="icon-delete-absolute" @click="deleteOldFiles(index)"></b-button>
                    </div>

                    <b-field class="m-2" v-else :key="index">
                      <p class="control">
                        <b-button tag="a" :href="file" target="_blank">Lihat File {{ index + 1 }}</b-button>
                      </p>
                      <p class="control">
                        <b-button type="is-danger" icon-left="close" @click="deleteOldFiles(index)"/>
                      </p>
                    </b-field>
                  </template>
                </div>
              </div>
            </b-field>

            <div class="is-flex mt-4">
              <b-button type="is-primary" expanded class="has-text-weight-light mr-2" v-if="disabled"
                        @click="$emit('close')">
                Close
              </b-button>
              <b-button type="is-primary" expanded class="has-text-weight-light" native-type="submit" v-else>
                Save
              </b-button>
              <b-button type="is-success" expanded class="has-text-weight-light ml-2" @click="handleSubmit(true)" v-if="saved"
                        icon-left="printer-outline">
                Save & Print
              </b-button>
            </div>
          </form>
        </ValidationObserver>
      </section>

      <b-modal v-model="isImageModalActive">
        <img :src="image" alt="">
      </b-modal>

    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import { parse as parseCurrency } from "vue-currency-input"

export default {
  name: "NotaHasilPanenInvoiceForm",
  props: {
    order: Object,
    disabled: Boolean,
    notaPanen: Object,
  },
  data: () => {
    return {
      image: null,
      isImageModalActive: false,

      currencyOptions: {
        currency: 'IDR',
        locale: 'id-ID',
        distractionFree: false,
        allowNegative: false,
        precision: 0,
      },
      file: null,

      files: [],
      old_files: [],

      data: {
        crops: [
          {},
          {},
          {},
        ],
        farmer_sign: null,
      },
      saved: false,
    }
  },
  computed: {
    totalPrice () {
      return this.data.crops.reduce((a,b) => {
        return a + (b.total_price || 0)
      }, 0)
    },
  },
  watch: {
    file: {
      deep:true,
      handler () {
        this.data.payment_file = null
      },
    },
    order: {
      deep: true,
      handler() {
        this.resetData()
      }
    },
  },
  created() {
    this.resetData()
  },
  methods: {
    ...mapActions('supplyChain', [
      'addNotaPanen',
      'updateNotaPanen',
    ]),


    ...mapActions('upload', [
      'uploadFile',
    ]),

    resetData() {
      if (this.notaPanen) {
        let saved = true

        if (this.notaPanen.payment_file) this.old_files = this.notaPanen.payment_file.split(',')

        this.data = {
          ...cloneDeep(this.notaPanen),
          crops: cloneDeep(this.notaPanen.crops).map(e=> {
            e.total_price = 0
            if (typeof e.price_per_kg !== 'number') {
              saved = false
            } else {
              e.total_price = (e.qty && e.price_per_kg) ? this.$number(e.qty * e.price_per_kg) : 0
              e.price_per_kg_text = e.price_per_kg
            }
            return e
          }),
          harvest_date: new Date(this.notaPanen.harvest_date),
        }

        this.saved = saved
      }
    },

    updateTotalPrice (item, value = null) {
      if (typeof value === 'string') {
        item.price_per_kg = parseCurrency(value, this.currencyOptions)
      }

      item.total_price = 0

      if (item.price_per_kg && item.qty) {
        item.total_price = this.$number(item.price_per_kg * item.qty)
      }
    },

    async handleSubmit(print = false) {
      this.$refs.form.validate().then(async success => {
        if (success) {
          this.$loading()

          let crops = cloneDeep(this.data.crops)

          let payload  = {
            ...this.data,
            crops,
            payment_time: this.data.payment_method === 'Cash' ? 0 : this.data.payment_time,
            payment_date: null,
            harvest_date: this.$timestamp(this.data.harvest_date)
          }

          if (this.files && this.files.length) {
            let promises = []
            for (let i = 0; i < this.files.length; i++) {
              promises.push(this.uploadFile(this.files[i]))
            }
            await Promise.all(promises).then(res => {
              payload.payment_file = this.old_files || []
              for (let i = 0; i < res.length; i++) {
                payload.payment_file.push(res[i].url)
              }
              payload.payment_file = payload.payment_file.join(',')
            })
          }

          if (this.data.payment_method === 'Tempo Pembayaran') {
            payload.payment_date = this.$timestamp(this.$moment(this.data.harvest_date).add(this.data.payment_time, 'weeks'))
          }

          let action = this.notaPanen ? this.updateNotaPanen : this.addNotaPanen

          return action(payload).then(() => {
            this.$emit('save')
            this.$emit('close')
            this.saved = true
            if (print) this.print()
          }).catch(err => {
            console.log(err)
          }).finally(() => {
            this.$loading(false)
          })
        }
      })
    },

    handleSubmitPrint() {
      this.handleSubmit(true)
    },

    print () {
      let routeData = this.$router.resolve({path: '/supply-chain/nota-hasil-panen/' + this.notaPanen.nota_panen_id + '/invoice'});
      window.open(routeData.href, '_blank');
    },

    openImage(url) {
      this.image = url
      this.isImageModalActive = true
    },

    deleteDropFile(index) {
      this.files.splice(index, 1);
    },

    deleteOldFiles(index) {
      this.old_files.splice(index, 1);
    },
  }
}
</script>

<style scoped lang="scss">
.card {
  width: 1200px !important;
  max-width: 100%;
}

.farmer-sign {
  border: 1px solid hsl(0deg, 0%, 86%);
}
</style>
