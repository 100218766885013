<template>
  <div>
    <div class="is-flex is-justify-content-space-between is-flex-wrap-wrap is-align-items-center mb-3">
      <div class="is-flex is-flex-wrap-wrap">
        <template v-if="!isSupplyChain">
          <b-select placeholder="Pilih Metode Pembayaran" v-model="selectedPaymentMethod" class="mr-2">
            <option value="">Pilih Metode Pembayaran</option>
            <option value="Tempo Pembayaran">Tempo Pembayaran</option>
            <option value="Cash">Cash</option>
            <option value="-">Belum Ada Metode</option>
          </b-select>
          <b-select placeholder="Pilih Status Pembayaran" v-model="selectedStatus" class="mr-2">
            <option value="">Pilih Status Pembayaran</option>
            <option value="Lunas">Lunas</option>
            <option value="Belum Lunas">Belum Lunas</option>
          </b-select>
          <b-button @click="resetFilter">Reset</b-button>
        </template>
      </div>
      <div class="has-text-right">
        <b-button type="is-primary" class="mr-2" @click="add">Tambah Nota Hasil Panen</b-button>
      </div>
    </div>


    <div class="is-flex">
      Total Data: <span class="has-text-primary has-text-weight-bold ml-2">{{ filteredData.length }}</span>
    </div>

    <b-table
      :loading="nota_panen.loading"
      :paginated="paginated"
      :per-page="perPage"
      :striped="true"
      :hoverable="true"
      default-sort="nota_panen_id"
      default-sort-direction="desc"
      :mobile-cards="false"
      sticky-header
      :data="filteredData">

      <b-table-column label="#" field="nota_panen_id" sortable v-slot="props" searchable width="100px">
        #{{ props.row.nota_panen_id }}
      </b-table-column>
      <b-table-column label="Nama Petambak" field="farmer_name" sortable v-slot="props" searchable>
        {{ props.row.farmer_name }}
      </b-table-column>
      <b-table-column label="Lokasi Petambak" field="farmer_location" sortable v-slot="props" searchable>
        {{ props.row.farmer_location }}
      </b-table-column>
      <b-table-column label="Tanggal Panen" field="harvest_date" sortable v-slot="props">
        {{ $dateFormat(props.row.harvest_date) }}
      </b-table-column>
      <b-table-column label="Metode Pembayaran" field="payment_method" sortable v-slot="props" :visible="!isSupplyChain">
        {{ props.row.payment_method || '-' }}
      </b-table-column>
      <b-table-column label="Tanggal Pembayaran" field="payment_date" sortable v-slot="props" :visible="!isSupplyChain">
        <span v-if="props.row.payment_method === 'Tempo Pembayaran'">{{ $dateFormat(props.row.payment_date) }}</span>
        <span v-else>-</span>
      </b-table-column>
      <b-table-column label="Sisa Hari Pembayaran" field="sisa" sortable v-slot="props" :visible="!isSupplyChain">
        <b-tag type="is-success" v-if="props.row.sisa === 'lunas'">Lunas</b-tag>
        <span v-else>{{ props.row.sisa }}</span>
      </b-table-column>
      <b-table-column label="Total Nilai" field="total_price" sortable v-slot="props" :visible="!isSupplyChain">
        {{ props.row.total_price ? $currency(props.row.total_price) : '-' }}
      </b-table-column>
      <b-table-column label="Aksi" v-slot="props">
        <div class="is-flex is-flex-wrap-wrap is-justify-content-flex-end">
          <b-button class="mb-2" type="is-success" target="_blank" tag="router-link"
                    :to="`/supply-chain/nota-hasil-panen/${props.row.nota_panen_id}`">Print
          </b-button>
          <b-button class="ml-2 mb-2" type="is-info" @click="invoice(props.row)" v-if="user.data.dashboard_role !== 'supply_chain'">Nota Pembelian</b-button>
          <b-button class="ml-2 mb-2" type="is-warning" @click="edit(props.row)">Edit</b-button>
          <b-button class="ml-2 mb-2" type="is-danger" @click="destroy(props.row.nota_panen_id)">Delete</b-button>
        </div>
      </b-table-column>

      <template #detail="props">
        <div class="columns" v-if="!props.row.infoLoading">
          <div class="column" v-if="props.row.user">
            <div class="columns is-gapless mb-2">
              <div class="column">Owner</div>
              <div class="column">:
                <span class="has-text-weight-bold cursor-pointer is-underline has-text-primary ml-2"
                      @click="clickToCopy" title="Click to Copy to Clipboard">
                    {{ props.row.user ? props.row.user.username : props.row.user_id }}
                  </span>
              </div>
            </div>
            <div class="columns is-gapless mb-2">
              <div class="column">Phone Number</div>
              <div class="column">:
                <span class="has-text-weight-bold cursor-pointer is-underline has-text-primary ml-2"
                      @click="clickToCopy" title="Click to Copy to Clipboard">
                    {{ props.row.user ? props.row.user.phone : '' }}
                  </span>
              </div>
            </div>
            <div class="columns is-gapless mb-2">
              <div class="column">Email</div>
              <div class="column">:
                <span class="has-text-weight-bold cursor-pointer is-underline has-text-primary ml-2"
                      @click="clickToCopy" title="Click to Copy to Clipboard">
                    {{ props.row.user ? props.row.user.email : '' }}
                  </span>
              </div>
            </div>
          </div>
          <div class="column" v-if="props.row.info">
            <div class="columns is-gapless mb-2">
              <div class="column">Total Block</div>
              <div class="column">: <span class="has-text-weight-bold ml-2">{{ props.row.info.block_count }}</span>
              </div>
            </div>
            <div class="columns is-gapless mb-2">
              <div class="column">Total Pond</div>
              <div class="column">: <span class="has-text-weight-bold ml-2">{{ props.row.info.pond_count }}</span>
              </div>
            </div>
            <div class="columns is-gapless">
              <div class="column">Total User</div>
              <div class="column">: <span class="has-text-weight-bold ml-2">{{ props.row.info.user_count }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="loader" style="height: 25px; width: 25px;margin: 0 auto" v-else></div>

      </template>

      <section class="section" slot="empty">
        <div class="content has-text-grey has-text-centered">
          <template v-if="nota_panen.loading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large"/>
            </p>
            <p>Fetching data...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="emoticon-sad" size="is-large"/>
            </p>
            <p>Nothing's there&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>
    <b-modal
      v-model="addModal"
      has-modal-card
      :destroy-on-hide="true"
      aria-role="dialog"
      custom-class="modal-nota-panen"
      aria-modal>
      <template #default="props">
        <NotaHasilPanenForm :nota-panen="selectedNotaPanen" @save="getNotaPanen" @close="props.close"/>
      </template>
    </b-modal>
    <b-modal
      v-model="invoiceModal"
      has-modal-card
      :destroy-on-hide="true"
      aria-role="dialog"
      custom-class="modal-nota-panen"
      aria-modal>
      <template #default="props">
        <NotaHasilPanenInvoiceForm :nota-panen="selectedNotaPanen" @close="props.close" @save="getNotaPanen"/>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import NotaHasilPanenForm from "@/components/SupplyChain/NotaHasilPanenForm";
import NotaHasilPanenInvoiceForm from "@/components/SupplyChain/NotaHasilPanenInvoiceForm";

export default {
  name: 'NotaHasilPanenTable',
  components: {NotaHasilPanenInvoiceForm, NotaHasilPanenForm},
  data() {
    return {
      paginated: true,
      perPage: 10,
      addModal: false,
      invoiceModal: false,

      selectedNotaPanen: null,

      selectedPaymentMethod: '',
      selectedStatus: '',
    }
  },
  computed: {
    ...mapGetters('auth', [
      'user',
      'isSupplyChain',
    ]),

    ...mapState('supplyChain', [
      'nota_panen',
    ]),

    filteredData() {
      let data = this.nota_panen.data

      if (this.selectedPaymentMethod) {
        if (this.selectedPaymentMethod === '-') data = data.filter(e => !e.payment_method)
        else data = data.filter(e => e.payment_method === this.selectedPaymentMethod)
      }

      if (this.selectedStatus) {
        if (this.selectedStatus === 'Lunas') data = data.filter(e => e.sisa === 'lunas')
        else data = data.filter(e => e.sisa !== 'lunas')
      }

      return data
    },
  },
  watch: {
    'refresh.value'(value) {
      if (value) this.getNotaPanen()
    },
  },
  created() {
    this.getNotaPanen()
  },
  methods: {
    ...mapActions('supplyChain', [
      'getNotaPanen',
      'deleteNotaPanen'
    ]),

    resetFilter () {
      this.selectedPaymentMethod = ''
      this.selectedStatus = ''
    },

    clickToCopy(e) {
      const el = document.createElement('textarea');
      el.value = e.target.innerText;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      this.$swal({
        icon: 'success',
        title: 'Copied to clipboard',
      })
    },

    add () {
      this.selectedNotaPanen = null
      this.addModal = true
    },

    edit(item) {
      this.selectedNotaPanen = cloneDeep(item)
      this.addModal = true
    },

    invoice(item) {
      this.selectedNotaPanen = cloneDeep(item)
      this.invoiceModal = true
    },

    destroy(id) {
      this.$swal
        .fire({
          title: "Hapus Nota Panen?",
          type: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ya",
          cancelButtonText: "Batal",
        })
        .then(result => {
          if (result.value) {
            this.deleteNotaPanen(id)
              .then(() => {
                this.getNotaPanen()
              })
              .catch(() => {
              });
          }
        })
    },
  }
}
</script>

<style lang="scss" scoped>

.farm-info {
  position: fixed;
  left: 40px;
  bottom: 40px;
  z-index: 100;
  width: 600px;
  opacity: .86;
  transition: opacity .15s;

  &:hover {
    opacity: 1;
  }
}

.modal-nota-panen {
  ::v-deep {
    .animation-content {
      max-width: 100% !important;
    }
  }
}

.b-table {
  ::v-deep {
    .table-wrapper.has-sticky-header {
      height: auto !important;
    }

    .th-wrap {
      .input {
        min-width: 55px;
      }
    }
  }
}

</style>
