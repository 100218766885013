var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card is-shadowless"},[_c('div',{staticClass:"card-content"},[_c('header',{staticClass:"mb-5"},[_c('div',{staticClass:"is-flex is-justify-content-space-between is-align-items-center"},[_c('h2',{staticClass:"is-size-6 has-text-dark has-text-weight-bold mr-3"},[_vm._v("Nota Hasil Panen")]),_c('div',{on:{"click":function($event){return _vm.$emit('close')}}},[_c('b-icon',{staticClass:"cursor-pointer",attrs:{"icon":"close-thick"}})],1)])]),_c('section',{staticClass:"my-2"},[_c('ValidationObserver',{ref:"form"},[_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"name":"Nama Petambak","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Nama Petambak","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{model:{value:(_vm.data.farmer_name),callback:function ($$v) {_vm.$set(_vm.data, "farmer_name", $$v)},expression:"data.farmer_name"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Lokasi Petambak","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Lokasi Petambak","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{model:{value:(_vm.data.farmer_location),callback:function ($$v) {_vm.$set(_vm.data, "farmer_location", $$v)},expression:"data.farmer_location"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Nama Staf","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Nama Staf","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{model:{value:(_vm.data.staff_name),callback:function ($$v) {_vm.$set(_vm.data, "staff_name", $$v)},expression:"data.staff_name"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Tanggal Panen","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Tanggal Panen","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-datepicker',{attrs:{"placeholder":"Pilih Tanggal","open-on-focus":"","locale":"id-ID"},model:{value:(_vm.data.harvest_date),callback:function ($$v) {_vm.$set(_vm.data, "harvest_date", $$v)},expression:"data.harvest_date"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Tipe Panen","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Tipe Panen","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-select',{attrs:{"placeholder":"Pilih Tipe","expanded":""},model:{value:(_vm.data.type),callback:function ($$v) {_vm.$set(_vm.data, "type", $$v)},expression:"data.type"}},[_c('option',{attrs:{"value":"Parsial"}},[_vm._v("Parsial")]),_c('option',{attrs:{"value":"Total"}},[_vm._v("Total")])])],1)]}}])}),_c('h2',{staticClass:"has-text-weight-bold ml-3 is-size-4"},[_vm._v("Petak")]),_c('div',{staticClass:"is-divider"}),_c('b-table',{staticClass:"mb-4",attrs:{"data":_vm.data.crops,"mobile-cards":false}},[_c('b-table-column',{attrs:{"label":"Nama Petak","field":"name"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('ValidationProvider',{attrs:{"name":("Nama Petak " + (props.index + 1))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"mb-0",attrs:{"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{staticStyle:{"min-width":"100px"},model:{value:(props.row.name),callback:function ($$v) {_vm.$set(props.row, "name", $$v)},expression:"props.row.name"}})],1)]}}],null,true)})]}}])}),_c('b-table-column',{attrs:{"label":"Size","field":"size"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('ValidationProvider',{attrs:{"name":("Size " + (props.index + 1))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"mb-0",attrs:{"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{staticStyle:{"min-width":"80px"},attrs:{"type":"number","step":"any"},model:{value:(props.row.size),callback:function ($$v) {_vm.$set(props.row, "size", _vm._n($$v))},expression:"props.row.size"}})],1)]}}],null,true)})]}}])}),_c('b-table-column',{attrs:{"label":"Quantity (Kg)","field":"qty"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('ValidationProvider',{attrs:{"name":("Quantity " + (props.index + 1))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"mb-0",attrs:{"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{staticStyle:{"min-width":"100px"},attrs:{"type":"number","step":"any"},model:{value:(props.row.qty),callback:function ($$v) {_vm.$set(props.row, "qty", _vm._n($$v))},expression:"props.row.qty"}})],1)]}}],null,true)})]}}])}),_c('b-table-column',{attrs:{"label":"","field":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-button',{attrs:{"type":"is-danger"},on:{"click":function($event){return _vm.deleteCrop(props.index)}}},[_c('b-icon',{attrs:{"icon":"trash-can-outline"}})],1)]}}])})],1),_c('div',{staticClass:"has-text-right"},[_c('b-button',{staticClass:"mt-2",attrs:{"type":"is-success","icon-left":"plus"},on:{"click":_vm.addCrop}},[_vm._v(" Tambah Petak ")])],1),(_vm.disabled)?_c('b-button',{staticClass:"has-text-weight-light mt-4",attrs:{"type":"is-primary","expanded":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Close ")]):_c('b-button',{staticClass:"has-text-weight-light mt-4",attrs:{"type":"is-primary","expanded":"","native-type":"submit"}},[_vm._v(" Save ")])],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }