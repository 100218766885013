<template>
  <div class="card is-shadowless">
    <div class="card-content">
      <header class="mb-5">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <h2 class="is-size-6 has-text-dark has-text-weight-bold mr-3">Nota Hasil Panen</h2>
          <div @click="$emit('close')">
            <b-icon class="cursor-pointer" icon="close-thick"/>
          </div>
        </div>
      </header>
      <section class="my-2">
        <ValidationObserver ref="form">
          <form autocomplete="off" @submit.prevent="handleSubmit">
            <ValidationProvider name="Nama Petambak" rules="required" v-slot="{ errors }">
              <b-field label="Nama Petambak" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input v-model="data.farmer_name"/>
              </b-field>
            </ValidationProvider>
            <ValidationProvider name="Lokasi Petambak" rules="required" v-slot="{ errors }">
              <b-field label="Lokasi Petambak" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input v-model="data.farmer_location"/>
              </b-field>
            </ValidationProvider>
            <ValidationProvider name="Nama Staf" rules="required" v-slot="{ errors }">
              <b-field label="Nama Staf" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input v-model="data.staff_name"/>
              </b-field>
            </ValidationProvider>
            <ValidationProvider name="Tanggal Panen" rules="required" v-slot="{ errors }">
              <b-field label="Tanggal Panen" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-datepicker placeholder="Pilih Tanggal"
                              open-on-focus
                              v-model="data.harvest_date"
                              locale="id-ID">
                </b-datepicker>
              </b-field>
            </ValidationProvider>
            <ValidationProvider name="Tipe Panen" rules="required" v-slot="{ errors }">
              <b-field label="Tipe Panen" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-select placeholder="Pilih Tipe" v-model="data.type" expanded>
                  <option value="Parsial">Parsial</option>
                  <option value="Total">Total</option>
                </b-select>
              </b-field>
            </ValidationProvider>

            <h2 class="has-text-weight-bold ml-3 is-size-4">Petak</h2>

            <div class="is-divider"></div>

            <b-table class="mb-4" :data='data.crops' :mobile-cards="false">
              <b-table-column label="Nama Petak" field="name" v-slot="props">
                <ValidationProvider :name="`Nama Petak ${props.index + 1}`" v-slot="{ errors }">
                  <b-field class="mb-0" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input v-model="props.row.name" style="min-width: 100px"/>
                  </b-field>
                </ValidationProvider>
              </b-table-column>

              <b-table-column label="Size" field="size" v-slot="props">
                <ValidationProvider :name="`Size ${props.index + 1}`" v-slot="{ errors }">
                  <b-field class="mb-0" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input type="number" step="any" v-model.number="props.row.size" style="min-width: 80px"/>
                  </b-field>
                </ValidationProvider>
              </b-table-column>

              <b-table-column label="Quantity (Kg)" field="qty" v-slot="props">
                <ValidationProvider :name="`Quantity ${props.index + 1}`" v-slot="{ errors }">
                  <b-field class="mb-0" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input type="number" step="any" v-model.number="props.row.qty" style="min-width: 100px"/>
                  </b-field>
                </ValidationProvider>
              </b-table-column>
              <b-table-column label="" field="" v-slot="props">
                <b-button type="is-danger" @click="deleteCrop(props.index)">
                  <b-icon icon="trash-can-outline"/>
                </b-button>
              </b-table-column>
            </b-table>

            <div class="has-text-right">
              <b-button type="is-success" class="mt-2" @click="addCrop" icon-left="plus">
                Tambah Petak
              </b-button>
            </div>

<!--            <ValidationProvider name="Tanda Tangan Petambak" rules="required" v-slot="{ errors }">-->
<!--              <b-field label="Tanda Tangan Petambak" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">-->
<!--                <div>-->
<!--                  <vueSignature class="farmer-sign" ref="signature" :sigOption="option" :w="'400px'" :h="'200px'" :defaultUrl="farmerSign"></vueSignature>-->
<!--                  <div class="has-text-right mt-2">-->
<!--                    <b-button class="ml-auto" @click="clearSignature">Clear</b-button>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <input type="hidden" v-model="data.farmer_sign">-->
<!--              </b-field>-->
<!--            </ValidationProvider>-->
<!--            <ValidationProvider name="Tanda Tangan Staff" rules="required" v-slot="{ errors }">-->
<!--              <b-field label="Tanda Tangan Staff" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">-->
<!--                <div>-->
<!--                  <vueSignature class="farmer-sign" ref="signatureStaff" :sigOption="option" :w="'400px'" :h="'200px'" :defaultUrl="staffSign"></vueSignature>-->
<!--                  <div class="has-text-right mt-2">-->
<!--                    <b-button class="ml-auto" @click="clearSignatureStaff">Clear</b-button>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <input type="hidden" v-model="data.staff_sign">-->
<!--              </b-field>-->
<!--            </ValidationProvider>-->

            <b-button type="is-primary" expanded class="has-text-weight-light mt-4" v-if="disabled"
                      @click="$emit('close')">
              Close
            </b-button>
            <b-button type="is-primary" expanded class="has-text-weight-light mt-4" native-type="submit" v-else>
              Save
            </b-button>
          </form>
        </ValidationObserver>
      </section>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
// import vueSignature from "vue-signature"

export default {
  name: "NotaHasilPanenForm",
  components: {
    // vueSignature,
  },
  props: {
    order: Object,
    disabled: Boolean,
    notaPanen: Object,
  },
  data: () => {
    return {
      option:{
        penColor:"rgb(0, 0, 0)",
        backgroundColor:"rgb(255,255,255)"
      },

      data: {
        crops: [
          {},
          {},
          {},
        ],
        farmer_sign: null,
        staff_sign: null,
      },
      farmerSign: null,
      staffSign: null,
    }
  },
  watch: {
    order: {
      deep: true,
      handler() {
        this.resetData()
      }
    },
  },
  created() {
    this.resetData()
  },
  methods: {
    ...mapActions('supplyChain', [
      'addNotaPanen',
      'updateNotaPanen',
    ]),

    addCrop() {
      this.data.crops.push({})
    },

    deleteCrop(key) {
      this.data.crops.splice(key, 1)
      if (this.data.crops.length === 0) this.addCrop()
    },

    // clearSignature() {
    //   this.$refs.signature.clear()
    //   this.data.farmer_sign = null
    // },

    // clearSignatureStaff() {
    //   this.$refs.signatureStaff.clear()
    //   this.data.staff_sign = null
    // },

    resetData() {
      if (this.notaPanen) {
        this.data = {
          farmer_sign: null,
          staff_sign: null,
          ...cloneDeep(this.notaPanen),
          harvest_date: new Date(this.notaPanen.harvest_date),
        }
        if (this.notaPanen.farmer_sign) this.farmerSign = this.notaPanen.farmer_sign
        if (this.notaPanen.staff_sign) this.staffSign = this.notaPanen.staff_sign
      }
    },

    async handleSubmit() {
      // this.data.farmer_sign = await this.$refs.signature.save('image/jpeg')
      // this.data.staff_sign = await this.$refs.signatureStaff.save('image/jpeg')

      this.$nextTick(() => {
        this.$refs.form.validate().then(success => {
          if (success) {
            this.$loading()

            let crops = cloneDeep(this.data.crops).filter(e => e.name && e.qty && e.size)

            let payload = {
              ...this.data,
              crops,
              harvest_date: this.$timestamp(this.data.harvest_date),
            }

            let action = this.notaPanen ? this.updateNotaPanen : this.addNotaPanen

            return action(payload).then(() => {
              this.$emit('save')
              this.$emit('close')
            }).catch(err => {
              console.log(err)
            }).finally(() => {
              this.$loading(false)
            })
          }
        })
      })
    },
  }
}
</script>

<style scoped lang="scss">
.card {
  width: 1200px !important;
  max-width: 100%;

  .card-content {
    overflow: auto;

    @media (max-width: 768px) {
      padding: .75rem;
    }
  }
}

.farmer-sign {
  border: 1px solid hsl(0deg, 0%, 86%);
}
</style>
