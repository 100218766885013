<template>
  <div class="card is-shadowless delivery-note-form">
    <div class="card-content">
      <header class="mb-5">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <h2 class="is-size-6 has-text-dark has-text-weight-bold mr-3">Surat Jalan</h2>
          <div @click="$emit('close')">
            <b-icon class="cursor-pointer" icon="close-thick"/>
          </div>
        </div>
      </header>
      <section class="my-2">
        <ValidationObserver ref="form">
          <form autocomplete="off" @submit.prevent="handleSubmit">
            <ValidationProvider name="No. Pol. Kendaraan" rules="required" v-slot="{ errors }">
              <b-field label="No. Pol. Kendaraan" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input v-model="data.car_number_license"/>
              </b-field>
            </ValidationProvider>

            <ValidationProvider name="Tujuan" rules="required" v-slot="{ errors }">
              <b-field label="Tujuan" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input v-model="data.destination"/>
              </b-field>
            </ValidationProvider>

            <ValidationProvider name="Nama Staff" rules="required" v-slot="{ errors }">
              <b-field label="Nama Staff" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input v-model="data.staff_name"/>
              </b-field>
            </ValidationProvider>

            <ValidationProvider name="Nama Petambak" rules="required" v-slot="{ errors }">
              <b-field label="Nama Petambak" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-taginput
                  v-model="data.farmer_name"
                  icon="label"
                  type="is-primary"
                  placeholder="Tambah Nama Petambak"
                  aria-close-label="Delete this tag">
                </b-taginput>
              </b-field>
            </ValidationProvider>

            <ValidationProvider name="Lokasi Tambak" rules="required" v-slot="{ errors }">
              <b-field label="Lokasi Tambak" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-taginput
                  v-model="data.farmer_location"
                  icon="label"
                  type="is-primary"
                  placeholder="Tambah Lokasi Tambak"
                  aria-close-label="Delete this tag">
                </b-taginput>
              </b-field>
            </ValidationProvider>

            <ValidationProvider name="Tanggal Panen" rules="required" v-slot="{ errors }">
              <b-field label="Tanggal Panen" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-datepicker placeholder="Pilih Tanggal"
                              open-on-focus
                              v-model="data.harvest_date"
                              locale="id-ID">
                </b-datepicker>
              </b-field>
            </ValidationProvider>

            <h2 class="has-text-weight-bold ml-3 is-size-4">Box</h2>
            <div class="is-divider"></div>
            <b-table class="mb-4" :data="data.delivery_boxes" :mobile-cards="false">
              <b-table-column label="Nomor Box" field="box_num" v-slot="props">
                <ValidationProvider :name="`Nomor Box ${props.index + 1}`" v-slot="{ errors }">
                  <b-field class="mb-0" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input :value="props.index + 1" disabled/>
                  </b-field>
                </ValidationProvider>
              </b-table-column>

              <b-table-column label="Nomor Segel" field="seal_number" v-slot="props">
                <b-field v-for="(item, key) in props.row.seal_numbers" :key="key" :class="{
                    'mb-0': key === props.row.seal_numbers.length - 1
                  }">
                  <div>
                    <b-input v-model="item.value" style="min-width: 100px"/>
                    <div class="has-text-right">
                      <b-button type="is-success"
                                @click="addSealNumber(props.row)"
                                size="is-small"
                                class="mt-2"
                                v-if="key === props.row.seal_numbers.length - 1"
                      >
                        + Tambah Nomor Segel
                      </b-button>
                    </div>
                  </div>
                </b-field>
              </b-table-column>

              <b-table-column label="Nama Petak" field="crop" v-slot="props">
                <b-field v-for="(item, key) in props.row.delivery_box_crops" :key="key" :class="{
                  'mb-0': key === props.row.delivery_box_crops.length - 1
                }">
                  <div>
                    <b-input v-model="item.name" style="min-width: 80px;"/>
                    <div class="has-text-right">
                      <b-button type="is-success"
                                @click="addCrop(props.row.delivery_box_crops)"
                                size="is-small"
                                class="mt-2"
                                v-if="key === props.row.delivery_box_crops.length - 1"
                      >
                        + Tambah Petak
                      </b-button>
                    </div>
<!--                    <b-button type="is-danger" @click="deleteCrop(props.row.delivery_box_crops, key)" size="is-small">-->
<!--                      - -->
<!--                    </b-button>-->
                  </div>
                </b-field>
              </b-table-column>

              <b-table-column label="Size" field="size" v-slot="props">
                <b-field v-for="(item, key) in props.row.delivery_box_crops" :key="key" :class="{
                  'mb-0': key === props.row.delivery_box_crops.length - 1
                }">
                  <div class="is-flex is-align-items-center">
                    <b-input type="number" v-model.number="item.size" step="any" style="min-width: 80px;"/>
                  </div>
                </b-field>
              </b-table-column>

              <b-table-column label="Qty" field="qty" v-slot="props">
                <b-field v-for="(item, key) in props.row.delivery_box_crops" :key="key">
                  <div class="is-flex is-align-items-center">
                    <b-input type="number" v-model.number="item.qty" step="any" style="min-width: 80px;"/>
                  </div>
                </b-field>
                <b-input type="number" v-model.number="subTotalQty[props.index]" disabled step="any" style="min-width: 80px;"/>
              </b-table-column>

              <b-table-column label="Note" field="note" v-slot="props">
                <b-field v-for="(item, key) in props.row.delivery_box_crops" :key="key" :class="{
                  'mb-0': key === props.row.delivery_box_crops.length - 1
                }">
                  <div class="is-flex is-align-items-center">
                    <b-input type="text" v-model="item.note" style="min-width: 100px"/>
                  </div>
                </b-field>
              </b-table-column>

              <b-table-column label="" field="" v-slot="props" cell-class="has-text-right">
                <b-button type="is-danger" @click="deleteDeliveryBox(props.index)">
                  <b-icon icon="trash-can-outline"/>
                </b-button>
              </b-table-column>

              <template #footer v-if="data.delivery_boxes.length > 1">
                <th><div class="th-wrap"></div></th>
                <th><div class="th-wrap"></div></th>
                <th><div class="th-wrap"></div></th>
                <th><div class="th-wrap"></div></th>
                <th><div class="th-wrap"><b-input v-model.number="totalQty" step="any" disabled/></div></th>
                <th><div class="th-wrap"></div></th>
              </template>

            </b-table>

            <div class="has-text-right">
              <b-button type="is-success" class="mt-2" @click="addDeliveryBox">
                Tambah Box
              </b-button>
            </div>

<!--            <ValidationProvider name="Tanda Tangan Staff" rules="required" v-slot="{ errors }">-->
<!--              <b-field label="Tanda Tangan Staff" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">-->
<!--                <div>-->
<!--                  <vueSignature class="farmer-sign" ref="signatureStaff" :sigOption="option" :w="'400px'" :h="'200px'" :defaultUrl="staffSign"></vueSignature>-->
<!--                  <div class="has-text-right mt-2">-->
<!--                    <b-button class="ml-auto" @click="clearSignature">Clear</b-button>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <input type="hidden" v-model="data.staff_sign">-->
<!--              </b-field>-->
<!--            </ValidationProvider>-->

            <b-button type="is-primary" expanded class="has-text-weight-light mt-4" v-if="disabled"
                      @click="$emit('close')">
              Close
            </b-button>
            <b-button type="is-primary" expanded class="has-text-weight-light mt-4" native-type="submit" v-else>
              Save
            </b-button>
          </form>
        </ValidationObserver>
      </section>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
// import vueSignature from "vue-signature";

export default {
  name: "DeliveryNoteForm",
  components: {
    // vueSignature,
  },
  props: {
    disabled: Boolean,
    deliveryNote: Object,
  },
  data: () => {
    return {
      option:{
        penColor:"rgb(0, 0, 0)",
        backgroundColor:"rgb(255,255,255)"
      },

      data: {
        delivery_boxes: [
          {
            seal_numbers: [{}],
            delivery_box_crops: [
              {},
              {},
              {},
            ],
          },
          {
            seal_numbers: [{}],
            delivery_box_crops: [
              {},
              {},
              {},
            ],
          },
          {
            seal_numbers: [{}],
            delivery_box_crops: [
              {},
              {},
              {},
            ],
          },
        ],
        staff_sign: null,
      },
      filteredCrops: [],

      staffSign: null,
    }
  },
  computed: {
    ...mapState('supplyChain', [
      'nota_panen',
    ]),

    subTotalQty() {
      return this.data.delivery_boxes.reduce((a,b) => {
        let item = 0
        for (let i = 0; i < b.delivery_box_crops.length; i++) {
          item += b.delivery_box_crops[i].qty || 0
        }
        a.push(item)
        return a
      }, [])
    },

    totalQty () {
      return this.subTotalQty.reduce((a,b) => a + b, 0)
    },

    notaPanen () {
      return this.nota_panen.data.filter(e => e.crops && e.crops.length)
    },

    crops () {
      return this.notaPanen.reduce((a,b) => a.concat(b.crops.map(e=> {
        e.search_crop = '#' + b.nota_panen_id + ' ' + b.farmer_name + ' - ' + e.name + ' - ' + this.$dateFormat(b.harvest_date)
        return e
      })), [])
    },
  },
  created() {
    this.resetData()
  },
  methods: {
    ...mapActions('supplyChain', [
      'addDeliveryNote',
      'updateDeliveryNote',
    ]),

    addDeliveryBox() {
      this.data.delivery_boxes.push({
        seal_numbers: [{}],
        delivery_box_crops: [
          {},
          {},
          {},
        ],
      })
    },

    deleteDeliveryBox(key) {
      this.data.delivery_boxes.splice(key, 1)
      if (this.data.delivery_boxes.length === 0) this.addDeliveryBox()
    },

    addSealNumber(item) {
      item.seal_numbers.push({})
      this.$forceUpdate()
    },

    addCrop(item) {
      item.push({})
    },

    deleteCrop(item, key) {
      item.splice(key, 1)
      if (item.length === 0) this.addCrop(item)
    },

    // clearSignature() {
    //   this.$refs.signatureStaff.clear()
    //   this.data.staff_sign = null
    // },

    findCrop(crop_id) {
      return this.crops.find(e => e.crop_id === crop_id)
    },

    resetData() {
      if (this.deliveryNote) {
        this.data = {
          staff_sign: null,
          ...cloneDeep(this.deliveryNote),
          delivery_boxes: this.deliveryNote.delivery_boxes.map(e=> {
            e.seal_numbers = e.seal_number.split(',').map(f => {
              return {
                value: f
              }
            })
            return e
          }),
          farmer_name: this.deliveryNote.farmer_name ? this.deliveryNote.farmer_name.split(',') : [],
          farmer_location: this.deliveryNote.farmer_location ? this.deliveryNote.farmer_location.split(',') : [],
          harvest_date: new Date(this.deliveryNote.harvest_date)
        }

        if (this.deliveryNote.staff_sign)
          if (this.deliveryNote.staff_sign) this.staffSign = this.deliveryNote.staff_sign

        for (let i = 0; i < this.data.delivery_boxes.length; i++) {
          let deliveryBox = this.data.delivery_boxes[i]
          delete deliveryBox.delivery_box_id
          deliveryBox.delivery_box_crops = deliveryBox.delivery_box_crops.map(e=> {
            delete e.delivery_box_crop_id
            delete e.delivery_box_id
            return e
          })
        }
      }
    },

    ...mapActions('supply', [
      'updateOrder'
    ]),

    async handleSubmit() {
      // this.data.staff_sign = await this.$refs.signatureStaff.save('image/jpeg')

      this.$nextTick(() => {
        this.$refs.form.validate().then(success => {
          if (success) {
            this.$loading()

            let delivery_boxes = cloneDeep(this.data.delivery_boxes).map(e => {
              e.delivery_box_crops = e.delivery_box_crops.filter(f => f.name && f.qty && f.size).map(f => {
                delete f.search_crop
                return f
              })
              e.seal_number = e.seal_numbers.filter(e => e.value).map(e => e.value).join(',') || ''
              delete e.seal_numbers
              return e
            }).filter(e => typeof e.seal_number === 'string' && e.delivery_box_crops.length)

            let payload = {
              ...this.data,
              delivery_boxes,
              farmer_name: this.data.farmer_name.join(','),
              farmer_location: this.data.farmer_location.join(','),
              harvest_date: this.$timestamp(this.data.harvest_date),
            }

            let action = this.deliveryNote ? this.updateDeliveryNote : this.addDeliveryNote

            return action(payload).then(() => {
              this.$emit('save')
              this.$emit('close')
            }).catch(err => {
              console.log(err)
            }).finally(() => {
              this.$loading(false)
            })
          }
        })
      })
    },
  }
}
</script>

<style scoped lang="scss">
.card {
  width: 1200px !important;
  max-width: 100%;
}

.delivery-note-form {
  ::v-deep {
    .autocomplete .dropdown-menu {
      width: unset;
      min-width: 100%;
    }
  }
  .card-content {
    overflow: auto;

    @media (max-width: 768px) {
      padding: .75rem;
    }
  }
}

.farmer-sign {
  border: 1px solid hsl(0deg, 0%, 86%);
}
</style>
