<template>
  <div class="card is-shadowless delivery-note-form">
    <div class="card-content">
      <header class="mb-5">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <h2 class="is-size-6 has-text-dark has-text-weight-bold mr-3">Surat Jalan - Bongkar</h2>
          <div @click="$emit('close')">
            <b-icon class="cursor-pointer" icon="close-thick"/>
          </div>
        </div>
      </header>
      <section class="my-2">
        <ValidationObserver ref="form">
          <form autocomplete="off" @submit.prevent="handleSubmit">
            <b-collapse
              :open="false"
              position="is-bottom"
              aria-id="contentIdForA11y4">
              <template #trigger="props">
                <a
                  aria-controls="contentIdForA11y4"
                  class="is-flex is-align-items-center"
                  :aria-expanded="props.open">
                  <b-icon :icon="!props.open ? 'menu-down' : 'menu-up'"></b-icon>
                  {{ !props.open ? 'Lihat Surat Jalan' : 'Tutup Surat Jalan' }}
                </a>
              </template>
              <ValidationProvider name="No. Pol. Kendaraan" rules="required" v-slot="{ errors }">
                <b-field label="No. Pol. Kendaraan" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                  <b-input v-model="data.car_number_license" disabled/>
                </b-field>
              </ValidationProvider>
              <ValidationProvider name="Tujuan" rules="required" v-slot="{ errors }">
                <b-field label="Tujuan" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                  <b-input v-model="data.destination" disabled/>
                </b-field>
              </ValidationProvider>
              <ValidationProvider name="Nama Staff" rules="required" v-slot="{ errors }">
                <b-field label="Nama Staff" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                  <b-input v-model="data.staff_name" disabled/>
                </b-field>
              </ValidationProvider>
              <ValidationProvider name="Nama Petambak" rules="required" v-slot="{ errors }">
                <b-field label="Nama Petambak" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                  <b-taginput
                    v-model="data.farmer_name"
                    icon="label"
                    type="is-primary"
                    placeholder="Tambah Nama Petambak"
                    aria-close-label="Delete this tag"
                    disabled>
                  </b-taginput>
                </b-field>
              </ValidationProvider>
              <ValidationProvider name="Lokasi Tambak" rules="required" v-slot="{ errors }">
                <b-field label="Lokasi Tambak" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                  <b-taginput
                    v-model="data.farmer_location"
                    icon="label"
                    type="is-primary"
                    placeholder="Tambah Lokasi Tambak"
                    aria-close-label="Delete this tag"
                    disabled>
                  </b-taginput>
                </b-field>
              </ValidationProvider>
              <ValidationProvider name="Tanggal Panen" rules="required" v-slot="{ errors }">
                <b-field label="Tanggal Panen" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                  <b-datepicker placeholder="Pilih Tanggal"
                                open-on-focus
                                v-model="data.harvest_date"
                                locale="id-ID"
                                disabled>
                  </b-datepicker>
                </b-field>
              </ValidationProvider>
              <ValidationProvider name="Total Qty" rules="required" v-slot="{ errors }">
                <b-field label="Total Qty (Kg)" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                  <b-input v-model="data.total_qty" disabled/>
                </b-field>
              </ValidationProvider>
            </b-collapse>

            <div class="is-divider"></div>

            <ValidationProvider name="Tanggal Bongkar" rules="required" v-slot="{ errors }">
              <b-field label="Tanggal Bongkar" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-datepicker placeholder="Pilih Tanggal"
                              open-on-focus
                              v-model="data.bongkar_date"
                              locale="id-ID">
                </b-datepicker>
              </b-field>
            </ValidationProvider>

            <b-field class="file is-primary" :class="{'has-name': !!bongkar_photo}">
              <div class="is-flex is-flex-direction-column is-align-items-start">
                <label class="label">Foto Hasil Bongkar</label>
                <b-upload v-model="bongkar_photo" class="file-label" accept="image/jpeg,image/png">
                  <span class="file-cta">
                    <b-icon class="file-icon" icon="upload"></b-icon>
                    <span class="file-label">Click to upload</span>
                  </span>
                  <span class="file-name" v-if="bongkar_photo">
                    {{ bongkar_photo.name }}
                  </span>
                </b-upload>

                <img :src="data.bongkar_photo" v-if="data.bongkar_photo" class="cursor-pointer mt-2" alt=""
                     style="max-width: 300px" @click="openImage(data.bongkar_photo)">
              </div>
            </b-field>

            <ValidationProvider name="Total Tonase Hasil Bongkar" rules="required" v-slot="{ errors }">
              <b-field label="Total Tonase Hasil Bongkar (Kg)" :type="errors[0] ? 'is-danger' : ''"
                       :message="errors[0]">
                <b-input type="number" step="any" v-model.number="data.bongkar_qty"/>
              </b-field>
            </ValidationProvider>

            <b-field class="file is-primary" :class="{'has-name': !!factory_invoices}">
              <div class="is-flex is-flex-direction-column is-align-items-start">
                <label class="label">Surat Perintah Pembayaran (SPP) / Nota Pembelian Pabrik</label>
                <b-upload v-model="factory_invoices" multiple drag-drop expanded class="mb-2"
                          accept="image/jpeg,image/png,application/pdf">
                  <section class="section">
                    <div class="content has-text-centered">
                      <p>
                        <b-icon icon="upload" size="is-large"></b-icon>
                      </p>
                      <p>Drop your files here or click to upload</p>
                    </div>
                  </section>
                </b-upload>
                <div class="tags">
                <span v-for="(file, index) in factory_invoices" :key="index" class="tag is-primary">
                  {{ file.name }}
                  <button class="delete is-small" type="button" @click="deleteFile(factory_invoices, index)"></button>
                </span>
                </div>
                <div class="is-flex is-flex-wrap-wrap">
                  <template v-for="(file, index) in old_factory_invoices">
                    <div class="is-flex is-relative" v-if="$isImage(file)" :key="index">
                      <img :src="file" class="cursor-pointer" alt=""
                           style="max-width: 300px" @click="openImage(file)">
                      <b-button type="is-danger" icon-left="close" class="icon-delete-absolute" @click="deleteFile(old_factory_invoices, index)"></b-button>
                    </div>

                    <b-field class="m-2" v-else :key="index">
                      <p class="control">
                        <b-button tag="a" :href="file" target="_blank">Lihat File {{ index + 1 }}</b-button>
                      </p>
                      <p class="control">
                        <b-button type="is-danger" icon-left="close" @click="deleteFile(old_factory_invoices, index)"/>
                      </p>
                    </b-field>
                  </template>
                </div>
              </div>
            </b-field>

            <ValidationProvider name="Total Nilai Pembayaran" rules="required" v-slot="{ errors }">
              <b-field label="Total Nilai Pembayaran" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input v-model="data.total_price_text" v-currency="currencyOptions" @input="updateTotalPrice"/>
              </b-field>
            </ValidationProvider>

            <ValidationProvider name="Pembayaran Dibayar" rules="required" v-slot="{ errors }">
              <b-field label="Pembayaran Dibayar" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input v-model="data.payment_paid_text" v-currency="currencyOptions" @input="updatePaymentPaid"/>
              </b-field>
            </ValidationProvider>

            <ValidationProvider name="Waktu Pembayaran" rules="required" v-slot="{ errors }">
              <b-field label="Waktu Pembayaran" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input v-model.number="data.payment_time" step="1" type="number"/>
                <p class="control">
                  <b-button class="button is-primary">Hari</b-button>
                </p>
              </b-field>
            </ValidationProvider>

            <b-field class="file is-primary" :class="{'has-name': !!files}">
              <div class="is-flex is-flex-direction-column is-align-items-start">
                <label class="label">Bukti Pembayaran</label>
                <b-upload v-model="files" multiple drag-drop expanded class="mb-2"
                          accept="image/jpeg,image/png,application/pdf">
                  <section class="section">
                    <div class="content has-text-centered">
                      <p>
                        <b-icon icon="upload" size="is-large"></b-icon>
                      </p>
                      <p>Drop your files here or click to upload</p>
                    </div>
                  </section>
                </b-upload>
                <div class="tags">
                <span v-for="(file, index) in files" :key="index" class="tag is-primary">
                  {{ file.name }}
                  <button class="delete is-small" type="button" @click="deleteFile(files, index)"></button>
                </span>
                </div>
                <div class="is-flex is-flex-wrap-wrap">
                  <template v-for="(file, index) in old_files">
                    <div class="is-flex is-relative" v-if="$isImage(file)" :key="index">
                      <img :src="file" class="cursor-pointer" alt=""
                           style="max-width: 300px" @click="openImage(file)">
                      <b-button type="is-danger" icon-left="close" class="icon-delete-absolute" @click="deleteFile(old_files, index)"></b-button>
                    </div>

                    <b-field class="m-2" v-else :key="index">
                      <p class="control">
                        <b-button tag="a" :href="file" target="_blank">Lihat File {{ index + 1 }}</b-button>
                      </p>
                      <p class="control">
                        <b-button type="is-danger" icon-left="close" @click="deleteFile(old_files, index)"/>
                      </p>
                    </b-field>
                  </template>
                </div>
              </div>
            </b-field>

            <b-button type="is-primary" expanded class="has-text-weight-light mt-4" v-if="disabled"
                      @click="$emit('close')">
              Close
            </b-button>
            <b-button type="is-primary" expanded class="has-text-weight-light mt-4" native-type="submit" v-else>
              Save
            </b-button>
          </form>
        </ValidationObserver>
      </section>

      <b-modal v-model="isImageModalActive">
        <img :src="image" alt="">
      </b-modal>

    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import {parse as parseCurrency} from "vue-currency-input"

export default {
  name: "DeliveryNoteBongkarForm",
  props: {
    disabled: Boolean,
    deliveryNote: Object,
  },
  data: () => {
    return {
      image: null,
      isImageModalActive: false,

      currencyOptions: {
        currency: 'IDR',
        locale: 'id-ID',
        distractionFree: false,
        allowNegative: false,
        precision: 0,
      },

      bongkar_photo: null,
      factory_invoices: [],

      data: {
        delivery_boxes: [
          {
            seal_numbers: [{}],
            delivery_box_crops: [
              {},
              {},
              {},
            ],
          },
          {
            seal_numbers: [{}],
            delivery_box_crops: [
              {},
              {},
              {},
            ],
          },
          {
            seal_numbers: [{}],
            delivery_box_crops: [
              {},
              {},
              {},
            ],
          },
        ],
      },
      filteredCrops: [],

      file: null,

      files: [],
      old_files: [],
      old_factory_invoices: [],
    }
  },
  computed: {
    ...mapState('supplyChain', [
      'nota_panen',
    ]),

    notaPanen() {
      return this.nota_panen.data.filter(e => e.crops && e.crops.length)
    },

    crops() {
      return this.notaPanen.reduce((a, b) => a.concat(b.crops.map(e => {
        e.search_crop = '#' + b.nota_panen_id + ' ' + b.farmer_name + ' - ' + e.name + ' - ' + this.$dateFormat(b.harvest_date)
        return e
      })), [])
    },
  },
  watch: {
    file: {
      deep: true,
      handler() {
        this.data.payment_file = null
      },
    },
    bongkar_photo: {
      deep: true,
      handler() {
        this.data.bongkar_photo = null
      },
    },
    factory_invoice: {
      deep: true,
      handler() {
        this.data.factory_invoice = null
      },
    },
  },
  created() {
    this.resetData()
  },
  methods: {
    ...mapActions('supplyChain', [
      'addDeliveryNote',
      'updateDeliveryNote',
    ]),

    ...mapActions('upload', [
      'uploadFile',
    ]),

    updateTotalPrice(value) {
      if (typeof value === 'string') {
        this.data.total_price = parseCurrency(value, this.currencyOptions)
      }
    },

    updatePaymentPaid(value) {
      if (typeof value === 'string') {
        this.data.payment_paid = parseCurrency(value, this.currencyOptions)
      }
    },

    addDeliveryBox() {
      this.data.delivery_boxes.push({
        seal_numbers: [{}],
        delivery_box_crops: [
          {},
          {},
          {},
        ],
      })
    },

    deleteDeliveryBox(key) {
      this.data.delivery_boxes.splice(key, 1)
      if (this.data.delivery_boxes.length === 0) this.addDeliveryBox()
    },

    addSealNumber(item) {
      item.seal_numbers.push({})
      this.$forceUpdate()
    },

    addCrop(item) {
      item.push({})
    },

    deleteCrop(item, key) {
      item.splice(key, 1)
      if (item.length === 0) this.addCrop(item)
    },

    findCrop(crop_id) {
      return this.crops.find(e => e.crop_id === crop_id)
    },

    resetData() {
      if (this.deliveryNote) {

        if (this.deliveryNote.payment_file) this.old_files = this.deliveryNote.payment_file.split(',')
        if (this.deliveryNote.factory_invoice) this.old_factory_invoices = this.deliveryNote.factory_invoice.split(',')

        this.data = {
          staff_sign: null,
          ...cloneDeep(this.deliveryNote),
          delivery_boxes: this.deliveryNote.delivery_boxes.map(e => {
            e.seal_numbers = e.seal_number.split(',').map(f => {
              return {
                value: f
              }
            })
            return e
          }),
          farmer_name: this.deliveryNote.farmer_name ? this.deliveryNote.farmer_name.split(',') : [],
          farmer_location: this.deliveryNote.farmer_location ? this.deliveryNote.farmer_location.split(',') : [],
          harvest_date: new Date(this.deliveryNote.harvest_date),
          bongkar_date: this.deliveryNote.bongkar_date ? new Date(this.deliveryNote.bongkar_date) : null,
          total_price_text: 0,
          payment_paid: 0,
        }

        if (typeof this.deliveryNote.total_price === 'number') {
          this.data.total_price_text = this.deliveryNote.total_price
        }

        if (typeof this.deliveryNote.payment_paid === 'number') {
          this.data.payment_paid_text = this.deliveryNote.payment_paid
        }

        if (this.deliveryNote.staff_sign)
          if (this.deliveryNote.staff_sign) this.staffSign = this.deliveryNote.staff_sign

        for (let i = 0; i < this.data.delivery_boxes.length; i++) {
          let deliveryBox = this.data.delivery_boxes[i]
          delete deliveryBox.delivery_box_id
        }
      }
    },

    ...mapActions('supply', [
      'updateOrder'
    ]),

    async handleSubmit() {
      this.$nextTick(() => {
        this.$refs.form.validate().then(async success => {
          if (success) {
            this.$loading()

            let payload = {
              ...this.data,
              farmer_name: this.data.farmer_name.join(','),
              farmer_location: this.data.farmer_location.join(','),
              harvest_date: this.$timestamp(this.data.harvest_date),
              bongkar_date: this.$timestamp(this.data.bongkar_date),
              payment_date: this.$timestamp(this.$moment(this.data.bongkar_date).add(this.data.payment_time, 'days')),
            }

            if (this.bongkar_photo)
              await this.uploadFile(this.bongkar_photo).then(res => {
                if (res && res.url) payload.bongkar_photo = res.url
              })
            if (this.factory_invoices && this.factory_invoices.length) {
              let promises = []
              for (let i = 0; i < this.factory_invoices.length; i++) {
                promises.push(this.uploadFile(this.factory_invoices[i]))
              }
              await Promise.all(promises).then(res => {
                payload.factory_invoice = this.old_factory_invoices || []
                for (let i = 0; i < res.length; i++) {
                  payload.factory_invoice.push(res[i].url)
                }
                payload.factory_invoice = payload.factory_invoice.join(',')
              })
            }

            if (this.files && this.files.length) {
              let promises = []
              for (let i = 0; i < this.files.length; i++) {
                promises.push(this.uploadFile(this.files[i]))
              }
              await Promise.all(promises).then(res => {
                payload.payment_file = this.old_files || []
                for (let i = 0; i < res.length; i++) {
                  payload.payment_file.push(res[i].url)
                }
                payload.payment_file = payload.payment_file.join(',')
              })
            }

            return this.updateDeliveryNote(payload).then(() => {
              this.$emit('save')
              this.$emit('close')
            }).catch(err => {
              console.log(err)
            }).finally(() => {
              this.$loading(false)
            })
          }
        })
      })
    },

    openImage(url) {
      this.image = url
      this.isImageModalActive = true
    },

    deleteFile(file, index) {
      file.splice(index, 1);
    },
  }
}
</script>

<style scoped lang="scss">
.card {
  width: 1200px !important;
  max-width: 100%;
}

.delivery-note-form {
  ::v-deep {
    .autocomplete .dropdown-menu {
      width: unset;
      min-width: 100%;
    }
  }

  .card-content {
    overflow: auto;

    @media (max-width: 768px) {
      padding: .75rem;
    }
  }
}
</style>
