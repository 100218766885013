var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card is-shadowless delivery-note-form"},[_c('div',{staticClass:"card-content"},[_c('header',{staticClass:"mb-5"},[_c('div',{staticClass:"is-flex is-justify-content-space-between is-align-items-center"},[_c('h2',{staticClass:"is-size-6 has-text-dark has-text-weight-bold mr-3"},[_vm._v("Surat Jalan")]),_c('div',{on:{"click":function($event){return _vm.$emit('close')}}},[_c('b-icon',{staticClass:"cursor-pointer",attrs:{"icon":"close-thick"}})],1)])]),_c('section',{staticClass:"my-2"},[_c('ValidationObserver',{ref:"form"},[_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"name":"No. Pol. Kendaraan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"No. Pol. Kendaraan","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{model:{value:(_vm.data.car_number_license),callback:function ($$v) {_vm.$set(_vm.data, "car_number_license", $$v)},expression:"data.car_number_license"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Tujuan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Tujuan","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{model:{value:(_vm.data.destination),callback:function ($$v) {_vm.$set(_vm.data, "destination", $$v)},expression:"data.destination"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Nama Staff","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Nama Staff","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{model:{value:(_vm.data.staff_name),callback:function ($$v) {_vm.$set(_vm.data, "staff_name", $$v)},expression:"data.staff_name"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Nama Petambak","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Nama Petambak","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-taginput',{attrs:{"icon":"label","type":"is-primary","placeholder":"Tambah Nama Petambak","aria-close-label":"Delete this tag"},model:{value:(_vm.data.farmer_name),callback:function ($$v) {_vm.$set(_vm.data, "farmer_name", $$v)},expression:"data.farmer_name"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Lokasi Tambak","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Lokasi Tambak","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-taginput',{attrs:{"icon":"label","type":"is-primary","placeholder":"Tambah Lokasi Tambak","aria-close-label":"Delete this tag"},model:{value:(_vm.data.farmer_location),callback:function ($$v) {_vm.$set(_vm.data, "farmer_location", $$v)},expression:"data.farmer_location"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Tanggal Panen","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Tanggal Panen","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-datepicker',{attrs:{"placeholder":"Pilih Tanggal","open-on-focus":"","locale":"id-ID"},model:{value:(_vm.data.harvest_date),callback:function ($$v) {_vm.$set(_vm.data, "harvest_date", $$v)},expression:"data.harvest_date"}})],1)]}}])}),_c('h2',{staticClass:"has-text-weight-bold ml-3 is-size-4"},[_vm._v("Box")]),_c('div',{staticClass:"is-divider"}),_c('b-table',{staticClass:"mb-4",attrs:{"data":_vm.data.delivery_boxes,"mobile-cards":false},scopedSlots:_vm._u([(_vm.data.delivery_boxes.length > 1)?{key:"footer",fn:function(){return [_c('th',[_c('div',{staticClass:"th-wrap"})]),_c('th',[_c('div',{staticClass:"th-wrap"})]),_c('th',[_c('div',{staticClass:"th-wrap"})]),_c('th',[_c('div',{staticClass:"th-wrap"})]),_c('th',[_c('div',{staticClass:"th-wrap"},[_c('b-input',{attrs:{"step":"any","disabled":""},model:{value:(_vm.totalQty),callback:function ($$v) {_vm.totalQty=_vm._n($$v)},expression:"totalQty"}})],1)]),_c('th',[_c('div',{staticClass:"th-wrap"})])]},proxy:true}:null],null,true)},[_c('b-table-column',{attrs:{"label":"Nomor Box","field":"box_num"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('ValidationProvider',{attrs:{"name":("Nomor Box " + (props.index + 1))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"mb-0",attrs:{"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"value":props.index + 1,"disabled":""}})],1)]}}],null,true)})]}}])}),_c('b-table-column',{attrs:{"label":"Nomor Segel","field":"seal_number"},scopedSlots:_vm._u([{key:"default",fn:function(props){return _vm._l((props.row.seal_numbers),function(item,key){return _c('b-field',{key:key,class:{
                    'mb-0': key === props.row.seal_numbers.length - 1
                  }},[_c('div',[_c('b-input',{staticStyle:{"min-width":"100px"},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('div',{staticClass:"has-text-right"},[(key === props.row.seal_numbers.length - 1)?_c('b-button',{staticClass:"mt-2",attrs:{"type":"is-success","size":"is-small"},on:{"click":function($event){return _vm.addSealNumber(props.row)}}},[_vm._v(" + Tambah Nomor Segel ")]):_vm._e()],1)],1)])})}}])}),_c('b-table-column',{attrs:{"label":"Nama Petak","field":"crop"},scopedSlots:_vm._u([{key:"default",fn:function(props){return _vm._l((props.row.delivery_box_crops),function(item,key){return _c('b-field',{key:key,class:{
                  'mb-0': key === props.row.delivery_box_crops.length - 1
                }},[_c('div',[_c('b-input',{staticStyle:{"min-width":"80px"},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}}),_c('div',{staticClass:"has-text-right"},[(key === props.row.delivery_box_crops.length - 1)?_c('b-button',{staticClass:"mt-2",attrs:{"type":"is-success","size":"is-small"},on:{"click":function($event){return _vm.addCrop(props.row.delivery_box_crops)}}},[_vm._v(" + Tambah Petak ")]):_vm._e()],1)],1)])})}}])}),_c('b-table-column',{attrs:{"label":"Size","field":"size"},scopedSlots:_vm._u([{key:"default",fn:function(props){return _vm._l((props.row.delivery_box_crops),function(item,key){return _c('b-field',{key:key,class:{
                  'mb-0': key === props.row.delivery_box_crops.length - 1
                }},[_c('div',{staticClass:"is-flex is-align-items-center"},[_c('b-input',{staticStyle:{"min-width":"80px"},attrs:{"type":"number","step":"any"},model:{value:(item.size),callback:function ($$v) {_vm.$set(item, "size", _vm._n($$v))},expression:"item.size"}})],1)])})}}])}),_c('b-table-column',{attrs:{"label":"Qty","field":"qty"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._l((props.row.delivery_box_crops),function(item,key){return _c('b-field',{key:key},[_c('div',{staticClass:"is-flex is-align-items-center"},[_c('b-input',{staticStyle:{"min-width":"80px"},attrs:{"type":"number","step":"any"},model:{value:(item.qty),callback:function ($$v) {_vm.$set(item, "qty", _vm._n($$v))},expression:"item.qty"}})],1)])}),_c('b-input',{staticStyle:{"min-width":"80px"},attrs:{"type":"number","disabled":"","step":"any"},model:{value:(_vm.subTotalQty[props.index]),callback:function ($$v) {_vm.$set(_vm.subTotalQty, props.index, _vm._n($$v))},expression:"subTotalQty[props.index]"}})]}}])}),_c('b-table-column',{attrs:{"label":"Note","field":"note"},scopedSlots:_vm._u([{key:"default",fn:function(props){return _vm._l((props.row.delivery_box_crops),function(item,key){return _c('b-field',{key:key,class:{
                  'mb-0': key === props.row.delivery_box_crops.length - 1
                }},[_c('div',{staticClass:"is-flex is-align-items-center"},[_c('b-input',{staticStyle:{"min-width":"100px"},attrs:{"type":"text"},model:{value:(item.note),callback:function ($$v) {_vm.$set(item, "note", $$v)},expression:"item.note"}})],1)])})}}])}),_c('b-table-column',{attrs:{"label":"","field":"","cell-class":"has-text-right"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-button',{attrs:{"type":"is-danger"},on:{"click":function($event){return _vm.deleteDeliveryBox(props.index)}}},[_c('b-icon',{attrs:{"icon":"trash-can-outline"}})],1)]}}])})],1),_c('div',{staticClass:"has-text-right"},[_c('b-button',{staticClass:"mt-2",attrs:{"type":"is-success"},on:{"click":_vm.addDeliveryBox}},[_vm._v(" Tambah Box ")])],1),(_vm.disabled)?_c('b-button',{staticClass:"has-text-weight-light mt-4",attrs:{"type":"is-primary","expanded":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Close ")]):_c('b-button',{staticClass:"has-text-weight-light mt-4",attrs:{"type":"is-primary","expanded":"","native-type":"submit"}},[_vm._v(" Save ")])],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }