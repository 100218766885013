<template>
  <div>
    <div class="is-flex is-flex-wrap-wrap is-justify-content-space-between is-align-items-center mb-3">
      <div class="is-flex">
        Total Data: <span class="has-text-primary has-text-weight-bold ml-2">{{ delivery_note.data.length }}</span>
      </div>
      <div class="has-text-right">
        <b-button type="is-primary" class="mr-2" @click="add">Tambah Surat Jalan</b-button>
      </div>
    </div>
    <b-table
      :loading="delivery_note.loading"
      :paginated="paginated"
      :per-page="perPage"
      :striped="true"
      :hoverable="true"
      :mobile-cards="false"
      default-sort="delivery_note_id"
      default-sort-direction="desc"
      sticky-header
      :data="delivery_note.data">

      <b-table-column label="#" field="delivery_note_id" sortable v-slot="props" searchable width="100px">
        #{{ props.row.delivery_note_id }}
      </b-table-column>
      <b-table-column label="No. Pol. Kendaraan" field="car_number_license" sortable v-slot="props" searchable>
        {{ props.row.car_number_license }}
      </b-table-column>
      <b-table-column label="Nama Petambak" field="farmer_name" sortable v-slot="props" searchable>
        {{ props.row.farmer_name.split(',').join(', ') }}
      </b-table-column>
      <b-table-column label="Tujuan" field="destination" sortable v-slot="props" searchable>
        {{ props.row.destination }}
      </b-table-column>
      <b-table-column label="Tanggal Panen" field="harvest_date" sortable v-slot="props">
        {{ $dateFormat(props.row.harvest_date) }}
      </b-table-column>
      <b-table-column label="Total Qty Panen" field="total_qty" sortable v-slot="props">
        {{ $number(props.row.total_qty) }} Kg
      </b-table-column>
      <b-table-column label="Tanggal Bongkar" field="total_qty" sortable v-slot="props">
        {{ props.row.bongkar_date ? $dateFormat(props.row.bongkar_date) : '-' }}
      </b-table-column>
      <b-table-column label="Total Tonase Bongkar" field="total_qty" sortable v-slot="props">
        {{ props.row.bongkar_qty ? props.row.bongkar_qty + ' Kg' : '-' }}
      </b-table-column>
      <b-table-column label="Selisih Tonase" field="total_qty" sortable v-slot="props">
        <span
          class="has-text-weight-bold"
          :class="{
            'has-text-success': props.row.selisih_tonase_percentage > 7,
            'has-text-warning': props.row.selisih_tonase_percentage >= 5 && props.row.selisih_tonase_percentage <= 7,
            'has-text-danger': props.row.selisih_tonase_percentage < 5,
          }"
          v-if="props.row.selisih_tonase">
          {{ props.row.selisih_tonase }} Kg ({{ props.row.selisih_tonase_percentage }}%)
        </span>
        <span v-else>-</span>
      </b-table-column>
      <b-table-column label="Total Nilai Pembayaran" field="total_price" sortable v-slot="props" :visible="!isSupplyChain">
        {{ props.row.total_price ? $currency(props.row.total_price) : '-' }}
        <p class="is-italic" v-if="props.row.payment_paid && props.row.payment_paid !== props.row.total_price">(Sisa: {{ $currency(props.row.total_price - props.row.payment_paid) }})</p>
      </b-table-column>
      <b-table-column label="Tanggal Pembayaran" field="payment_date" sortable v-slot="props" :visible="!isSupplyChain">
        {{ props.row.payment_date ? $dateFormat(props.row.payment_date) : '-' }}
      </b-table-column>
      <b-table-column label="Waktu Pembayaran" field="payment_time" sortable v-slot="props" :visible="!isSupplyChain">
        {{ props.row.payment_time ? props.row.payment_time + ' Hari' : '-' }}
      </b-table-column>
      <b-table-column label="Status" v-slot="props" :visible="!isSupplyChain">
        <b-tag type="is-success" v-if="props.row.payment_file">Lunas</b-tag>
        <b-tag type="is-secondary" v-else>Belum Lunas</b-tag>
      </b-table-column>
      <b-table-column label="Aksi" v-slot="props">
        <div class="is-flex is-flex-wrap-wrap is-justify-content-flex-end">
          <b-button class="mb-2" type="is-success" target="_blank" tag="router-link"
                    :to="`/supply-chain/delivery-note/${props.row.delivery_note_id}`">Print
          </b-button>
          <b-button class="mb-2 ml-2" type="is-info" @click="bongkar(props.row)" v-if="!isSupplyChain">Bongkar</b-button>
          <b-button class="mb-2 ml-2" type="is-warning" @click="edit(props.row)">Edit</b-button>
          <b-button class="mb-2 ml-2" type="is-danger" @click="destroy(props.row.delivery_note_id)">Delete</b-button>
        </div>
      </b-table-column>

      <template #detail="props">
        <div class="columns" v-if="!props.row.infoLoading">
          <div class="column" v-if="props.row.user">
            <div class="columns is-gapless mb-2">
              <div class="column">Owner</div>
              <div class="column">:
                <span class="has-text-weight-bold cursor-pointer is-underline has-text-primary ml-2"
                      @click="clickToCopy" title="Click to Copy to Clipboard">
                    {{ props.row.user ? props.row.user.username : props.row.user_id }}
                  </span>
              </div>
            </div>
            <div class="columns is-gapless mb-2">
              <div class="column">Phone Number</div>
              <div class="column">:
                <span class="has-text-weight-bold cursor-pointer is-underline has-text-primary ml-2"
                      @click="clickToCopy" title="Click to Copy to Clipboard">
                    {{ props.row.user ? props.row.user.phone : '' }}
                  </span>
              </div>
            </div>
            <div class="columns is-gapless mb-2">
              <div class="column">Email</div>
              <div class="column">:
                <span class="has-text-weight-bold cursor-pointer is-underline has-text-primary ml-2"
                      @click="clickToCopy" title="Click to Copy to Clipboard">
                    {{ props.row.user ? props.row.user.email : '' }}
                  </span>
              </div>
            </div>
          </div>
          <div class="column" v-if="props.row.info">
            <div class="columns is-gapless mb-2">
              <div class="column">Total Block</div>
              <div class="column">: <span class="has-text-weight-bold ml-2">{{ props.row.info.block_count }}</span>
              </div>
            </div>
            <div class="columns is-gapless mb-2">
              <div class="column">Total Pond</div>
              <div class="column">: <span class="has-text-weight-bold ml-2">{{ props.row.info.pond_count }}</span>
              </div>
            </div>
            <div class="columns is-gapless">
              <div class="column">Total User</div>
              <div class="column">: <span class="has-text-weight-bold ml-2">{{ props.row.info.user_count }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="loader" style="height: 25px; width: 25px;margin: 0 auto" v-else></div>

      </template>

      <section class="section" slot="empty">
        <div class="content has-text-grey has-text-centered">
          <template v-if="delivery_note.loading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large"/>
            </p>
            <p>Fetching data...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="emoticon-sad" size="is-large"/>
            </p>
            <p>Nothing's there&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>

    <b-modal
      v-model="addModal"
      has-modal-card
      :destroy-on-hide="true"
      aria-role="dialog"
      custom-class="modal-delivery-note"
      aria-modal>
      <template #default="props">
        <deliver-note-form :delivery-note="selectedDeliveryNote" @save="getDeliveryNote" @close="props.close"/>
      </template>
    </b-modal>

    <b-modal
      v-model="bongkarModal"
      has-modal-card
      :destroy-on-hide="true"
      aria-role="dialog"
      custom-class="modal-delivery-note"
      aria-modal>
      <template #default="props">
        <delivery-note-bongkar-form :delivery-note="selectedDeliveryNote" @save="getDeliveryNote" @close="props.close"/>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import DeliverNoteForm from "@/components/SupplyChain/DeliveryNoteForm";
import DeliveryNoteBongkarForm from "@/components/SupplyChain/DeliveryNoteBongkarForm";

export default {
  name: 'DeliveryNoteTable',
  components: {DeliveryNoteBongkarForm, DeliverNoteForm},
  data() {
    return {
      paginated: true,
      perPage: 10,
      farm_type: 0,
      checkedFarms: [],
      addModal: false,
      bongkarModal: false,

      farmInfo: null,
      farmInfoLoading: false,

      suratJalanModal: false,

      selectedDeliveryNote: null,
    }
  },
  computed: {
    ...mapState('supplyChain', [
      'delivery_note',
    ]),

    ...mapState('user', [
      'users',
    ]),

    ...mapGetters('auth', [
      'isSupplyChain',
    ]),
  },
  watch: {
    'refresh.value'(value) {
      if (value) this.loadData()
    },
  },
  created() {
    this.loadData(false)
  },
  methods: {
    async loadData(getNota = true) {
      if (getNota) await this.getNotaPanen()
      await this.getDeliveryNote()
    },

    ...mapActions('user', [
      'getUsers',
    ]),

    ...mapActions('supplyChain', [
      'getDeliveryNote',
      'getNotaPanen',
      'deleteDeliveryNote',
    ]),

    clickToCopy(e) {
      const el = document.createElement('textarea');
      el.value = e.target.innerText;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      this.$swal({
        icon: 'success',
        title: 'Copied to clipboard',
      })
    },

    showSuratJalan(item) {
      item.crops = item.crops.map(e => {
        e.qty = e.fresh + e.undersize + e.hollow + e.molting
        return e
      })
      this.selectedDeliveryNote = item
      this.suratJalanModal = true
    },

    add() {
      this.selectedDeliveryNote = null
      this.addModal = true
    },

    edit(item) {
      this.selectedDeliveryNote = cloneDeep(item)
      this.addModal = true
    },

    bongkar(item) {
      this.selectedDeliveryNote = cloneDeep(item)
      this.bongkarModal = true
    },

    destroy(id) {
      this.$swal
        .fire({
          title: "Hapus Surat Jalan?",
          type: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ya",
          cancelButtonText: "Batal",
        })
        .then(result => {
          if (result.value) {
            this.deleteDeliveryNote(id)
              .then(() => {
                this.getDeliveryNote()
              })
              .catch(() => {
              });
          }
        })
    },

    destroySelectedFarm() {
      this.$swal
        .fire({
          title: "Delete Selected Farm ?",
          html:
            "Your data will permanently delete from database! (Including Pond, Block, Cycle, Stock, Warehouse)",
          type: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Yes"
        })
        .then(result => {
          if (result.value) {
            this.delivery_note.loading = true

            let promises = []
            promises.push(new Promise((resolve => {
              for (let i = 0; i < this.checkedFarms.length; i++) {
                let farm = this.checkedFarms[i]
                this.deleteFarm(farm.farm_id)
                  .then(() => {
                    resolve(true)
                  })
                  .catch(() => {
                    resolve(true)
                  });
              }
            })))

            Promise.all(promises).finally(() => {
              this.getFarms()
            })
          }
        });
    },
  }
}
</script>

<style lang="scss" scoped>

.farm-info {
  position: fixed;
  left: 40px;
  bottom: 40px;
  z-index: 100;
  width: 600px;
  opacity: .86;
  transition: opacity .15s;

  &:hover {
    opacity: 1;
  }
}

.modal-delivery-note {
  ::v-deep {
    .animation-content {
      max-width: 100% !important;
    }
  }
}

.b-table {
  ::v-deep {
    .table-wrapper.has-sticky-header {
      height: auto !important;
    }

    .th-wrap {
      .input {
        min-width: 55px;
      }
    }
  }
}

</style>
