<template>
  <div>

    <template v-if="delivery_note.loading || nota_panen.loading">
      <div class="has-text-centered">
        <p>
          <b-icon icon="dots-horizontal" size="is-large"/>
        </p>
        <p>Fetching data...</p>
      </div>
    </template>
    <template v-else>
      <div class="is-flex is-justify-content-space-between is-flex-wrap-wrap mb-4">
        <div>
          <p class="is-size-4 has-text-weight-medium">Saldo Saat Ini</p>
          <p class="has-text-weight-bold is-size-2">{{ $currency(currentBalance) }}</p>
        </div>
        <div class="is-flex is-flex-wrap-wrap" style="gap: 20px">
          <b-field label="Filter Berdasarkan">
            <b-select placeholder="Filter Berdasarkan" v-model="selectedFilter">
              <option value="status">Status</option>
              <option value="tanggal">Tanggal</option>
            </b-select>
            <b-datepicker placeholder="Pilih Tanggal"
                          open-on-focus
                          position="is-bottom-left"
                          v-model="selectedDate"
                          locale="id-ID"
                          v-show="selectedFilter === 'tanggal'"
            />
          </b-field>
          <b-field label="Dari">
            <b-select placeholder="Dari" v-model="selectedFrom">
              <option value="">Semua</option>
              <option :value="item" v-for="(item, key) in fromData" :key="key">{{ item }}</option>
            </b-select>
          </b-field>
          <b-field label="Ke">
            <b-select placeholder="Ke" v-model="selectedTo">
              <option value="">Semua</option>
              <option :value="item" v-for="(item, key) in toData" :key="key">{{ item }}</option>
            </b-select>
          </b-field>
        </div>
      </div>

      <b-tabs type="is-toggle" v-model="selectedTab">
        <b-tab-item label="Upcoming">
          <div class="is-flex is-justify-content-space-between is-flex-wrap-wrap">
            <b-button readonly type="is-primary is-light" class="mb-3">Upcoming</b-button>
            <b-button readonly type="is-info is-light" class="mb-3" icon-left="file-download-outline"
                      @click="downloadUpcoming">Download CSV
            </b-button>
          </div>
          <b-table
            :paginated="false"
            :striped="true"
            :hoverable="true"
            default-sort="payment_date"
            default-sort-direction="asc"
            bordered
            :mobile-cards="false"
            :data="upcomingFinance">

            <template #empty>
              <p class="has-text-centered">No Data</p>
            </template>

            <b-table-column label="Kategori" field="type" v-slot="props">
            <span class="has-text-weight-bold" :class="{
              'has-text-success': props.row.type === 'income',
              'has-text-danger': props.row.type === 'expenses',
            }">
              {{ props.row.type === 'income' ? 'Pemasukan' : 'Pengeluaran' }}
            </span>
            </b-table-column>
            <b-table-column label="Tanggal Pembayaran" field="payment_date" sortable v-slot="props">
              {{ $dateFormat(props.row.payment_date) }}
            </b-table-column>
            <b-table-column label="Sisa Pembayaran" field="sisa" v-slot="props">
              {{ props.row.sisa }} Hari
            </b-table-column>
            <b-table-column label="Nominal" field="total_price" sortable v-slot="props">
              {{ $currency(props.row.total_price) }}
              <p class="is-italic" v-if="props.row.payment_paid && props.row.remaining_payment">(Sisa: {{ $currency(props.row.remaining_payment) }})</p>
            </b-table-column>
            <b-table-column label="Dari" field="from" sortable v-slot="props">
              {{ props.row.from }}
            </b-table-column>
            <b-table-column label="Ke" field="to" sortable v-slot="props">
              {{ props.row.to }}
            </b-table-column>
            <b-table-column label="Status" v-slot="props">
              <b-tag type="is-success" v-if="props.row.lunas">Lunas</b-tag>
              <b-tag type="is-secondary" v-else>Belum Lunas</b-tag>
            </b-table-column>

            <template #footer>
              <div>
                <div class="is-flex is-justify-content-space-between">
                  <div>
                    <p class="has-text-danger has-text-weight-bold">Total Nilai Pengeluaran</p>
                    <p class="has-text-success has-text-weight-bold">Total Nilai Pemasukan</p>
                  </div>
                  <div>
                    <p class="has-text-danger has-text-weight-bold">{{ $currency(totalUpcomingExpenses) }}</p>
                    <p class="has-text-success has-text-weight-bold">{{ $currency(totalUpcomingIncome) }}</p>
                  </div>
                </div>
                <div class="is-divider" style="border-color: rgb(245, 245, 245)"></div>
                <div class="is-flex is-justify-content-space-between">
                  <div>
                    <p class="has-text-weight-bold">Selisih Total</p>
                  </div>
                  <div>
                    <p class="has-text-weight-bold">{{ $currency(upcomingBalance) }}</p>
                  </div>
                </div>
              </div>
            </template>
          </b-table>
        </b-tab-item>
        <b-tab-item label="History">
          <div class="is-flex is-justify-content-space-between is-flex-wrap-wrap">
            <b-button readonly type="is-primary is-light" class="mb-3">History</b-button>
            <b-button readonly type="is-info is-light" class="mb-3" icon-left="file-download-outline"
                      @click="downloadHistory">Download CSV
            </b-button>
          </div>
          <b-table
            :paginated="true"
            :per-page="10"
            :striped="true"
            :hoverable="true"
            default-sort="payment_date"
            default-sort-direction="desc"
            bordered
            :mobile-cards="false"
            :data="financeHistory">

            <template #empty>
              <p class="has-text-centered">No Data</p>
            </template>

            <b-table-column label="Kategori" field="type" v-slot="props">
            <span class="has-text-weight-bold" :class="{
              'has-text-success': props.row.type === 'income',
              'has-text-danger': props.row.type === 'expenses',
            }">
              {{ props.row.type === 'income' ? 'Pemasukan' : 'Pengeluaran' }}
            </span>
            </b-table-column>
            <b-table-column label="Tanggal Pembayaran" field="payment_date" sortable v-slot="props">
              {{ $dateFormat(props.row.payment_date) }}
            </b-table-column>
            <b-table-column label="Waktu Pembayaran" field="payment_time" v-slot="props">
              {{ props.row.payment_time }}
            </b-table-column>
            <b-table-column label="Nominal" field="total_price" sortable v-slot="props">
              {{ $currency(props.row.total_price) }}
              <p class="is-italic" v-if="props.row.payment_paid && props.row.remaining_payment">(Sisa: {{ $currency(props.row.remaining_payment) }})</p>
            </b-table-column>
            <b-table-column label="Dari" field="from" sortable v-slot="props">
              {{ props.row.from }}
            </b-table-column>
            <b-table-column label="Ke" field="to" sortable v-slot="props">
              {{ props.row.to }}
            </b-table-column>
            <b-table-column label="Status" v-slot="props">
              <b-tag type="is-success" v-if="props.row.lunas">Lunas</b-tag>
              <b-tag type="is-secondary" v-else>Belum Lunas</b-tag>
            </b-table-column>
          </b-table>
        </b-tab-item>
      </b-tabs>
    </template>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import cloneDeep from "lodash/cloneDeep";

export default {
  name: 'SupplyChainReport',
  data: () => {
    return {
      selectedDate: null,
      selectedTab: 0,
      selectedFilter: 'status',
      selectedFrom: '',
      selectedTo: '',
    }
  },
  watch: {
    selectedDate() {
      this.$store.state.supplyChain.selectedDate = this.$timestamp(this.$startDay(this.selectedDate))
    },
    selectedFrom() {
      this.$store.state.supplyChain.selectedFrom = this.selectedFrom
    },
    selectedTo() {
      this.$store.state.supplyChain.selectedTo = this.selectedTo
    },
    selectedFilter(value) {
      if (value === 'status') this.$store.state.supplyChain.selectedDate = null
      else {
        this.selectedDate = new Date()
        this.$store.state.supplyChain.selectedDate = this.$timestamp(this.$startDay(this.selectedDate))
      }
    },
  },
  computed: {
    ...mapGetters('auth', [
      'user',
      'isSupplyChain',
    ]),

    ...mapState('supplyChain', [
      'nota_panen',
      'delivery_note',
    ]),

    ...mapGetters('supplyChain', [
      'upcomingFinance',
      'financeHistory',
      'currentBalance',
      'totalUpcomingIncome',
      'totalUpcomingExpenses',
      'upcomingBalance',
      'fromData',
      'toData',
    ]),
  },
  methods: {
    downloadUpcoming() {
      const data = [
        ['Kategori', 'Tanggal Pembayaran', 'Sisa Pembayaran', 'Nominal', 'Dari/Ke'],
        ...this.upcomingFinance.sort((a, b) => a.payment_date > b.payment_date ? 1 : -1).map(e => {
          return [
            e.type === 'income' ? 'Pemasukan' : 'Pengeluaran',
            this.$dateFormat(e.payment_date, 'YYYY/MM/DD'),
            e.sisa + ' Hari',
            e.total_price,
            e.name
          ]
        })
      ]

      let csvContent = "data:text/csv;charset=utf-8," + data.map(e => e.join(",")).join("\n");
      let encodedUri = encodeURI(csvContent);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);

      let filename = '[Sgara] - Upcoming Payment - ' + this.$dateFormat(this.selectedDate, 'YYYY-MM-DD') + '.csv'

      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    },
    downloadHistory() {
      const data = [
        ['Kategori', 'Tanggal Pembayaran', 'Waktu Pembayaran', 'Nominal', 'Dari', 'Ke'],
        ...this.financeHistory.sort((a, b) => b.payment_date > a.payment_date ? 1 : -1).map(e => {
          console.log(e.name)
          return [
            e.type === 'income' ? 'Pemasukan' : 'Pengeluaran',
            this.$dateFormat(e.payment_date, 'YYYY/MM/DD'),
            e.payment_time,
            e.total_price,
            e.from,
            e.to,
          ]
        })
      ]

      let csvContent = "data:text/csv;charset=utf-8," + data.map(e => e.join(",")).join("\n");
      let encodedUri = encodeURI(csvContent);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);

      let filename = '[Sgara] - Laporan Hutang Piutang - ' + this.$dateFormat(this.selectedDate, 'YYYY-MM-DD') + '.csv'

      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .tab-content {
    padding: 1rem 0;
  }
}
</style>
