<template>
  <div>
    <title-bar :title-stack="titleStack"/>
    <section class="section is-main-section">
      <card-component>
        <b-tabs>
          <b-tab-item label="Laporan Hutang Piutang" icon="finance" v-if="!isSupplyChain">
            <supply-chain-report/>
          </b-tab-item>
          <b-tab-item label="Nota Hasil Panen" icon="note-text">
            <nota-hasil-panen-table/>
          </b-tab-item>
          <b-tab-item label="Surat Jalan" icon="email-fast">
            <delivery-note-table/>
          </b-tab-item>
        </b-tabs>
      </card-component>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleBar from '@/components/TitleBar'
import NotaHasilPanenTable from "@/components/SupplyChain/NotaHasilPanenTable";
import DeliveryNoteTable from "@/components/SupplyChain/DeliveryNoteTable";
import CardComponent from "@/components/CardComponent";
import SupplyChainReport from "@/components/SupplyChain/SupplyChainReport";
import {mapGetters} from "vuex";

export default {
  name: 'NotaHasilPanen',
  components: {
    SupplyChainReport,
    CardComponent,
    DeliveryNoteTable,
    NotaHasilPanenTable,
    TitleBar
  },
  data: () => {
    return {}
  },
  computed: {
    ...mapGetters('auth', [
      'isSupplyChain',
    ]),

    titleStack() {
      return ['Supply Chain', 'Nota Hasil Panen']
    }
  },
}
</script>
